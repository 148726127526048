import type { PartnerPlaylistStateType } from './types'

const useActions = ({ state }: { state: PartnerPlaylistStateType }) => {
  const { API_URL } = useBaseUrl()
  const { token } = useAuthStorage()
  const { handleErrorInfo } = useCustomFetch()

  const fetchPartnerPlaylists = async ({ resolve = () => null }: any = {}) => {
    const { data, error, pending } = await useAsyncData(
      'partnerplaylists',
      () =>
        $fetch(`${API_URL}/users/partners/playlists`, {
          method: 'GET',
          headers: {
            Authorization: token.value
          }
        })
    )

    state.playlists.isLoading = pending.value

    if (!data.value && error.value) {
      state.playlists.isLoading = false
      handleErrorInfo(error, 'fetchPartnerPlaylists')
    } else {
      state.playlists = {
        data: useResult(data.value, 'data.playlists', null),
        isLoading: false
      }
    }

    resolve()
  }

  const fetchPartnerPlaylistDetail = async ({
    payload,
    resolve = () => null
  }: any = {}) => {
    const { data, error, pending } = await useAsyncData(
      'partnerplaylistDetail',
      () =>
        $fetch(`${API_URL}/users/partners/playlist/${payload}`, {
          method: 'GET',
          headers: {
            Authorization: token.value
          }
        })
    )

    state.playlistDetail.isLoading = pending.value

    if (!data.value && error.value) {
      state.playlistDetail.isLoading = false
      handleErrorInfo(error, 'fetchPartnerPlaylistDetail')
    } else {
      state.playlistDetail = {
        data: useResult(data.value, 'data', null),
        isLoading: false
      }
    }

    resolve()
  }

  const fetchPartnerPlaylistProgress = async ({
    payload,
    resolve = () => null
  }: any = {}) => {
    const { data, error, pending } = await useAsyncData(
      'fetchPartnerPlaylistProgress',
      () =>
        $fetch(`${API_URL}/users/partners/playlist/${payload}/progress`, {
          method: 'GET',
          headers: {
            Authorization: token.value
          }
        })
    )

    state.playlistProgress.isLoading = pending.value

    if (!data.value && error.value) {
      state.playlistProgress.isLoading = false
      handleErrorInfo(error, 'fetchPartnerPlaylistProgress')
    } else {
      state.playlistProgress = {
        data: useResult(data.value, 'data', null),
        isLoading: false
      }
    }

    resolve()
  }

  const fetchPartnerPlaylistFeeds = async ({
    payload,
    resolve = () => null
  }: any = {}) => {
    const { data, error, pending } = await useAsyncData(
      'fetchPartnerPlaylistFeeds',
      () =>
        $fetch(`${API_URL}/users/partners/playlist/${payload}/feeds`, {
          method: 'GET',
          headers: {
            Authorization: token.value
          }
        })
    )

    state.playlistFeeds.isLoading = pending.value

    if (!data.value && error.value) {
      state.playlistFeeds.isLoading = false
      handleErrorInfo(error, 'fetchPartnerPlaylistFeeds')
    } else {
      state.playlistFeeds = {
        data: useResult(data.value, 'data', null),
        isLoading: false
      }
    }

    resolve()
  }

  const fetchPartnerPlaylistCoursesInProgress = async ({
    payload,
    resolve = () => null
  }: any = {}) => {
    const { data, error, pending } = await useAsyncData(
      'fetchPartnerPlaylistCoursesInProgress',
      () =>
        $fetch(
          `${API_URL}/users/partners/playlist/${payload}/continue-progress`,
          {
            method: 'GET',
            headers: {
              Authorization: token.value
            }
          }
        )
    )

    state.playlistCoursesInProgress.isLoading = pending.value

    if (!data.value && error.value) {
      state.playlistCoursesInProgress.isLoading = false
      handleErrorInfo(error, 'fetchPartnerPlaylistCoursesInProgress')
    } else {
      state.playlistCoursesInProgress = {
        data: useResult(data.value, 'data', null),
        isLoading: false
      }
    }

    resolve()
  }

  const joinPartnerPlaylist = async ({
    payload,
    resolve = (groupSlug: string) => null
  }: any = {}) => {
    try {
      const data = await $fetch(
        `${API_URL}/users/partners/groups/member/join`,
        {
          method: 'POST',
          body: payload
        }
      )

      if (data) {
        resolve(useResult(data, 'data.urlSlug', null))
      }
    } catch (err) {
      handleErrorInfo({ value: err }, 'joinPartnerPlaylist')
    }
  }

  const activatePartnerPlaylistMember = async ({
    payload,
    resolve = (groupSlug: string) => null
  }: any = {}) => {
    try {
      const data = await $fetch(
        `${API_URL}/users/partners/groups/member/activate`,
        {
          method: 'POST',
          body: payload
        }
      )

      if (data) {
        resolve(useResult(data, 'data.urlSlug', null))
      }
    } catch (err) {
      handleErrorInfo({ value: err }, 'activatePartnerPlaylistMember')
    }
  }

  return {
    fetchPartnerPlaylists,
    fetchPartnerPlaylistDetail,
    fetchPartnerPlaylistProgress,
    fetchPartnerPlaylistFeeds,
    fetchPartnerPlaylistCoursesInProgress,
    joinPartnerPlaylist,
    activatePartnerPlaylistMember
  }
}

export default useActions
