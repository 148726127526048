import { defineStore } from 'pinia'
import type { PartnerPlaylistStateType } from './types'
import useActions from './actions'

export const usePartnerPlaylistStore = defineStore('partnerPlaylist', () => {
  const state = reactive<PartnerPlaylistStateType>({
    playlists: {
      data: null,
      isLoading: false
    },
    playlistDetail: {
      data: null,
      isLoading: false
    },
    playlistProgress: {
      data: null,
      isLoading: false
    },
    playlistFeeds: {
      data: null,
      isLoading: false
    },
    playlistCoursesInProgress: {
      data: null,
      isLoading: false
    }
  })

  const actions = useActions({ state })

  return { state, ...actions }
})
